import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';

import { access, doLogout } from '../services/user';

import CookieConsent from '../components/cookie-consent';
import { getConsent } from '../services/consent';
import SEO from '../components/seo';

const PrivacyPolicy = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [consentStatus, setConsentStatus] = useState(getConsent());

  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('privacy-policy_accessed');
      }
    }
  }, [consentStatus]);

  const logout = () => {
    doLogout()
      .then(() => {
        setIsLogged(false);
      })
      .catch(() => false);
  };

  useEffect(() => {
    const userStatus = access();
    Promise.resolve(userStatus)
      .then(function (value) {
        setIsLogged(value);
      })
      .catch((err) => {
        setIsLogged(false);
      });
  }, []);

  const cookieUpdate = (data) => {
    setConsentStatus(data);
    setShowCookie(false);
  };

  const toggleCookie = () => {
    setShowCookie(true);
  };

  return (
    <div className="has-navbar-fixed-top privacy-policy">
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO
        title="Privacy policy | Portica - All your projects in one place"
        description="Privacy Policy for Portica. Portica is a all-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects"
      ></SEO>
      <div>
        <Header
          logged={isLogged}
          consent={consentStatus}
          onLogout={logout}
          toggleCookie={toggleCookie}
        ></Header>
        <div className="portica-wrapper">
          <div className="section">
            <div className="is-stretch privacy-policy__content">
              <h1 className="title is-size-1">Portica Privacy Policy</h1>
              <p>
                Thanks for using Portica! Here we describe how we collect, use
                and handle your information when you use our websites, software
                and services ("Services").
              </p>
              <hr />
              <h3 className="title is-size-2">What &amp; Why</h3>
              <p>
                We collect and use the following information to provide, improve
                and protect our Services:
              </p>
              <p>
                <em>Account.</em> We collect, and associate with your account,
                information like your name, email address, phone number, payment
                info, and physical address. Some of our services let you access
                your accounts and your information with other service providers.
              </p>
              <p>
                <em>Services.</em> When you use our Services, we store, process
                and transmit your files (including stuff like your photos,
                structured data and emails) and information related to them (for
                example, location tags in photos). If you give us access to your
                contacts, we'll store those contacts on our servers for you to
                use. This will make it easy for you to do things like share your
                stuff, send emails, and invite others to use the Services.
              </p>
              <p>
                <em>Usage.</em> We collect information from and about the
                devices you use to access the Services. This includes things
                like IP addresses, the type of browser and device you use, the
                web page you visited before coming to our sites, and identifiers
                associated with your devices. Your devices (depending on their
                settings) may also transmit location information to the
                Services.
              </p>
              <p>
                <em>Cookies and other technologies.</em> We use technologies
                like cookies and pixel tags to provide, improve, protect and
                promote our Services. For example, cookies help us with things
                like remembering your username for your next visit,
                understanding how you are interacting with our Services, and
                improving them based on that information. You can set your
                browser to not accept cookies, but this may limit your ability
                to use the Services.
              </p>
              <hr />
              <h3 className="title is-size-2">With whom</h3>
              <p>
                We may share information as discussed below, but we won't sell
                it to advertisers or other third-parties.
              </p>
              <p>
                <em>Others working for Portica.</em> Portica uses certain
                trusted third parties to help us provide, improve, protect, and
                promote our Services. These third parties will access your
                information only to perform tasks on our behalf and in
                compliance with this Privacy Policy.
              </p>
              <p>
                <em>Other users.</em> Our Services display information like your
                name and email address to other users in places like your user
                profile and sharing notifications. Certain features let you make
                additional information available to other users.
              </p>
              <p>
                <em>Law &amp; Order.</em> We may disclose your information to
                third parties if we determine that such disclosure is reasonably
                necessary to (a) comply with the law; (b) protect any person
                from death or serious bodily injury; (c) prevent fraud or abuse
                of Portica or our users; or (d) protect Porticas property
                rights.
              </p>
              <p>
                Stewardship of your data is critical to us and a responsibility
                that we embrace. We believe that our users' data should receive
                the same legal protections regardless of whether it's stored on
                our services or on their home computer's hard drive. We'll abide
                by the following Government Request Principles when receiving,
                scrutinizing and responding to government requests for our
                users' data:
              </p>
              <ul>
                <li>Be transparent,</li>
                <li>Fight blanket requests,</li>
                <li>Protect all users, and</li>
                <li>Provide trusted services.</li>
              </ul>
              <hr />
              <h3 className="title is-size-2">How</h3>
              <p>
                <em>Security.</em> We keep your information secure and routinely
                test for vulnerabilities. We also continue to work on features
                to keep your information safe in addition to things like
                two-factor authentication, and encryption of files at rest.
              </p>
              <p>
                <em>Retention.</em> We'll retain information you store on our
                Services for as long as we need it to provide you the Services.
                If you delete your account, we'll also delete this information.
                But please note: (1) there might be some latency in deleting
                this information from our servers and back-up storage; and (2)
                we may retain this information if necessary to comply with our
                legal obligations, resolve disputes, or enforce our agreements.
              </p>
              <hr />
              <h3 className="title is-size-2">Where</h3>
              <p>
                <em>Around the world.</em> To provide you with the Services, we
                may store, process and transmit information in locations around
                the world - including those outside your country. Information
                may also be stored locally on the devices you use to access the
                Services.
              </p>
              <p>
                <em>Safe Harbor.</em> Portica accepts the EU-U.S. and Swiss-U.S.
                Safe Harbor ("Safe Harbor") frameworks and principles. You can
                learn more about Safe Harbor by visiting:
                <a href="http://export.gov/safeharbor">
                  http://export.gov/safeharbor
                </a>
                . JAMS is the independent organization responsible for reviewing
                and resolving complaints about our Safe Harbor compliance. We
                ask that you first submit any such complaints directly to us via
                info@portica.co. If you aren't satisfied with our response,
                please contact JAMS at{' '}
                <a href="http://www.jamsinternational.com/rules-procedures/safeharbor/file-safe-harbor-claim">
                  http://www.jamsinternational.com/rules-procedures/safeharbor/file-safe-harbor-claim
                </a>
                .
              </p>
              <hr />
              <h3 className="title is-size-2">Changes</h3>
              <p>
                If we are involved in a reorganization, merger, acquisition or
                sale of our assets, your information may be transferred as part
                of that deal. We will notify you (for example, via a message to
                the email address associated with your account) of any such deal
                and outline your choices in that event. We may revise this
                Privacy Policy from time to time, and will post the most current
                version on our website. If a revision meaningfully reduces your
                rights, we will notify you.
              </p>
              <hr />
              <h3 className="title is-size-2">Contact</h3>
              <p>
                Have questions or concerns about Portica, our Services and
                privacy? Contact us at{' '}
                <a href="mailto:info@porticapro.com">info@porticapro.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
